.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  border: 3px solid white;
  max-width: 1080px;
  max-height: 720px;
}
.background {
  width: 100%;
  height: 100%;
  grid-column-start: 1;
  grid-column-end: 40;
  grid-row-start: 1;
  grid-row-end: 40;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  z-index: 1;
}

.objects {
  width: 100%;
  height: 100%;
  grid-column-start: 1;
  grid-column-end: 40;
  grid-row-start: 1;
  grid-row-end: 40;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  z-index: 2;
}

.suggestion1 {
  width: 100%;
  height: 100%;
  grid-column-start: 9;
  grid-column-end: 10;
  grid-row-start: 5;
  grid-row-end: 19;
  z-index: 3;
  cursor: pointer;
  background-color: none;
  border: none;
}

.suggestion2 {
  width: 100%;
  height: 100%;
  grid-column-start: 10;
  grid-column-end: 11;
  grid-row-start: 5;
  grid-row-end: 19;
  z-index: 3;
  cursor: pointer;
  background-color: none;
  border: none;
}
