.Investigation {
  width: 80vw;
  min-height: 100vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
  display: block;
}

.Cctv {
  width: 80vw;
  margin-top: 10%;
}

.Notes {
  height: 10%;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
}

@media screen and (min-width: 769px) {
  /*desktop*/
  .Investigation {
    width: 90vw;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;
    display: flex;
  }

  .Cctv {
    width: 70%;
    margin-right: 2em;
  }

  .Notes {
    width: 30%;
    min-height: 50vh;
    margin-top: 10%;
    display: flex;
    flex-flow: row wrap;
  }
}
