.Conclusion {
  width: 80vw;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Ai {
  display: block;
  width: 20vh;
  height: 20vh;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
}

.AiText {
  width: 85%;
  height: 20vh;
  margin-top: 3%;
  padding: 5%;
  text-align: center;
  font-size: 1.3em;
  line-height: 1.5em;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.PlayerText {
  width: 85%;
  height: 20vh;
  margin-top: 5%;
  padding: 5%;
  text-align: center;
  font-size: 1.3em;
  line-height: 1.5em;
  border: 2px solid white;
  margin-left: auto;
  margin-right: auto;
  font-family: "Kalam", cursive;
  vertical-align: middle;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.texts {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.Conclusion span {
  display: inline;
  vertical-align: middle;
  margin: auto;
  cursor: default;
}

@media screen and (min-width: 769px) {
  .Conclusion {
    width: 80vw;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .Ai {
    display: block;
    width: 20vw;
    height: 20vw;
    object-fit: cover;
  }

  .texts {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
  }

  .AiText {
    width: 80%;
    height: 20vh;
    margin-top: 3%;
    padding: 5%;
    text-align: center;
    font-size: 1.3em;
    line-height: 1.5em;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .PlayerText {
    width: 80%;
    height: 20vh;
    margin-top: 5%;
    padding: 5%;
    text-align: center;
    font-size: 1.3em;
    line-height: 1.5em;
    border: 2px solid white;
    margin-left: auto;
    margin-right: auto;
    font-family: "Kalam", cursive;
    vertical-align: middle;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Conclusion span {
    display: inline;
    vertical-align: middle;
    margin: auto;
    cursor: default;
  }
}
