.briefing {
  background: linear-gradient(#49494d 5%, #2a2c3f 50%);
  width: 100vw;
  min-height: 100vh;
  display: block;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10%;
  font-family: "Pretendard Std";
  font-size: 1.3em;
}

.briefing div {
  padding: 1em;
  max-width: 1000px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.briefing button {
  width: 9em;
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 3em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  margin-bottom: 3em;
  font-size: 1.1em;
  border: none;
}

.briefing img {
  max-width: 1080px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
