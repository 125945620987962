@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch&family=Kalam:wght@300&display=swap");
body {
  margin: 0;
  font-family: "Pretendard Std";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #2a2c3f;

  color: #d1d5f0;
  line-height: 150%;

  overflow-x: hidden;
}

pre {
  font-family: "Pretendard Std";
  color: #d1d5f0;
  line-height: 150%;
}

h1 {
  color: #f1f3ff;
}

button {
  font-family: "Chakra Petch", sans-serif;
  background-color: #70b2e7;
  color: #f8f9ff;
  opacity: 0.8;
  transition: 0.3s;
  cursor: pointer;
}

button:hover {
  opacity: 1;
  color: #f8f9ff;
}

button:active {
  opacity: 1;
  color: #f8f9ff;
}

code {
  font-family: "Pretendard Std";
}

@font-face {
  font-family: "Pretendard Std";
  font-style: normal;
  font-weight: 100;
  src: url("styles/fonts/Pretendard-Light.woff") format("woff");
}

@font-face {
  font-family: "Pretendard Std";
  font-style: normal;
  font-weight: normal;
  src: url("styles/fonts/Pretendard-Regular.woff") format("woff");
}

@font-face {
  font-family: "Pretendard Std";
  font-style: normal;
  font-weight: 500;
  src: url("styles/fonts/Pretendard-Medium.woff") format("woff");
}

@font-face {
  font-family: "Pretendard Std";
  font-style: normal;
  font-weight: bold;
  src: url("styles/fonts/Pretendard-Bold.woff") format("woff");
}
