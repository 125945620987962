.day {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  height: 100vh;
}

.padding {
  height: 30vh;
}

.day h1 {
  font-size: 5em;
  text-align: center;
}
.day button {
  width: 10em;
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 3em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  font-size: 1.5em;
  border: none;
  margin-left: auto;
  margin-right: auto;
}
