.Notes div {
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: antiquewhite;
  font-family: "Kalam", cursive;
  color: black;
  padding: 1.4em;
}
.Notes button {
  width: 100%;
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 3em;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  font-size: 1.2em;
  border: none;
  margin-left: auto;
  margin-right: auto;
}
.Notes p {
  font-family: "Kalam", cursive;
  font-size: 1.2em;
}
@media screen and (min-width: 769px) {
  .Notes div {
    width: 100%;
    height: 100%;
    min-height: 50vh;
    text-align: center;
    background-color: antiquewhite;
    font-family: "Kalam", cursive;
    color: black;
    font-size: 1.2em;
  }
  .Notes button {
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 1em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    font-size: 1.2em;
    border: none;
    margin-left: auto;
    margin-right: auto;
  }
  .Notes p {
    font-family: "Kalam", cursive;
    font-size: 1.2em;
  }
}
